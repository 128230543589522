// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import AutomaticFormCreation from "../../blocks/AutomaticFormCreation/src/AutomaticFormCreation";
import CfZoomIntegration18 from "../../blocks/CfZoomIntegration18/src/CfZoomIntegration18";
import RecommendationEngine4 from "../../blocks/RecommendationEngine4/src/RecommendationEngine4";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import CfEarningPlatform from "../../blocks/CfEarningPlatform/src/CfEarningPlatform";
import QuestionBank from "../../blocks/QuestionBank/src/QuestionBank";
import MerchantDashboard2 from "../../blocks/MerchantDashboard2/src/MerchantDashboard2";
import BlogPostsManagement from "../../blocks/BlogPostsManagement/src/BlogPostsManagement";
import Leaderboard from "../../blocks/Leaderboard/src/Leaderboard";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Testimonials from "../../blocks/Testimonials/src/Testimonials";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import DataMigration2 from "../../blocks/DataMigration2/src/DataMigration2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import AccountScoreranking from "../../blocks/AccountScoreranking/src/AccountScoreranking";
import Payments from "../../blocks/Payments/src/Payments";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Videos from "../../blocks/videos/src/Videos";
import FullTeamVisibility2 from "../../blocks/FullTeamVisibility2/src/FullTeamVisibility2";
import Pagination2 from "../../blocks/Pagination2/src/Pagination2";
import Gamification from "../../blocks/Gamification/src/Gamification";
import Couponcodegenerator from "../../blocks/couponcodegenerator/src/Couponcodegenerator";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import Customform from "../../blocks/customform/src/Customform";
import JobListing2 from "../../blocks/JobListing2/src/JobListing2";
import GroupChat from "../../blocks/GroupChat/src/GroupChat";
import Gallery from "../../blocks/Gallery/src/Gallery";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import ActivityFeed from "../../blocks/ActivityFeed/src/ActivityFeed";
import Notifications from "../../blocks/notifications/src/Notifications";
import ContentModeration from "../../blocks/ContentModeration/src/ContentModeration";
import LiveStreaming from "../../blocks/LiveStreaming/src/LiveStreaming";
import DynamicContent from "../../blocks/DynamicContent/src/DynamicContent";
import Analytics from "../../blocks/analytics/src/Analytics";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import RefundManagement from "../../blocks/RefundManagement/src/RefundManagement";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import VideoConversation from "../../blocks/VideoConversation/src/VideoConversation";
import MatchAlgorithm2 from "../../blocks/MatchAlgorithm2/src/MatchAlgorithm2";
import ManageBlogComments from "../../blocks/ManageBlogComments/src/ManageBlogComments";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Scoring from "../../blocks/Scoring/src/Scoring";
import CfDigitalWalletIntegration from "../../blocks/CfDigitalWalletIntegration/src/CfDigitalWalletIntegration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Groups from "../../blocks/Groups/src/Groups";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import GoogleCalendarSync from "../../blocks/googlecalendarsync/src/GoogleCalendarSync";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Webinars from "../../blocks/Webinars/src/Webinars";
import ReceiptCustomisation from "../../blocks/ReceiptCustomisation/src/ReceiptCustomisation";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Sorting from "../../blocks/sorting/src/Sorting";
import CfMicrosoftDynamicsIntegration from "../../blocks/CfMicrosoftDynamicsIntegration/src/CfMicrosoftDynamicsIntegration";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import GroupVideoCall2 from "../../blocks/GroupVideoCall2/src/GroupVideoCall2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import BulkCommentManagement from "../../blocks/BulkCommentManagement/src/BulkCommentManagement";
import PremiumPlanComparison from "../../blocks/PremiumPlanComparison/src/PremiumPlanComparison";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import AssessmentTest from "../../blocks/AssessmentTest/src/AssessmentTest";
import ApplePayIntegration from "../../blocks/ApplePayIntegration/src/ApplePayIntegration";
import ImageThumbnail from "../../blocks/ImageThumbnail/src/ImageThumbnail";
import FileAttachment from "../../blocks/FileAttachment/src/FileAttachment";
import CfCryptoWalletPayment from "../../blocks/CfCryptoWalletPayment/src/CfCryptoWalletPayment";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LiveChat2 from "../../blocks/LiveChat2/src/LiveChat2";
import VideoManagement from "../../blocks/VideoManagement/src/VideoManagement";
import PerformanceTracker from "../../blocks/PerformanceTracker/src/PerformanceTracker";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Bookmark2 from "../../blocks/Bookmark2/src/Bookmark2";
import CommunityForum from "../../blocks/CommunityForum/src/CommunityForum";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";



const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
AutomaticFormCreation:{
 component:AutomaticFormCreation,
path:"/AutomaticFormCreation"},
CfZoomIntegration18:{
 component:CfZoomIntegration18,
path:"/CfZoomIntegration18"},
RecommendationEngine4:{
 component:RecommendationEngine4,
path:"/RecommendationEngine4"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
CfEarningPlatform:{
 component:CfEarningPlatform,
path:"/CfEarningPlatform"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
MerchantDashboard2:{
 component:MerchantDashboard2,
path:"/MerchantDashboard2"},
BlogPostsManagement:{
 component:BlogPostsManagement,
path:"/BlogPostsManagement"},
Leaderboard:{
 component:Leaderboard,
path:"/Leaderboard"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Testimonials:{
 component:Testimonials,
path:"/Testimonials"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
DataMigration2:{
 component:DataMigration2,
path:"/DataMigration2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
AdminConsole2:{
 component:AdminConsole2,
path:"/AdminConsole2"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
AccountScoreranking:{
 component:AccountScoreranking,
path:"/AccountScoreranking"},
Payments:{
 component:Payments,
path:"/Payments"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Videos:{
 component:Videos,
path:"/Videos"},
FullTeamVisibility2:{
 component:FullTeamVisibility2,
path:"/FullTeamVisibility2"},
Pagination2:{
 component:Pagination2,
path:"/Pagination2"},
Gamification:{
 component:Gamification,
path:"/Gamification"},
Couponcodegenerator:{
 component:Couponcodegenerator,
path:"/Couponcodegenerator"},
PaymentAdmin2:{
 component:PaymentAdmin2,
path:"/PaymentAdmin2"},
Customform:{
 component:Customform,
path:"/Customform"},
JobListing2:{
 component:JobListing2,
path:"/JobListing2"},
GroupChat:{
 component:GroupChat,
path:"/GroupChat"},
Gallery:{
 component:Gallery,
path:"/Gallery"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
ContentModeration:{
 component:ContentModeration,
path:"/ContentModeration"},
LiveStreaming:{
 component:LiveStreaming,
path:"/LiveStreaming"},
DynamicContent:{
 component:DynamicContent,
path:"/DynamicContent"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
RefundManagement:{
 component:RefundManagement,
path:"/RefundManagement"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
VideoConversation:{
 component:VideoConversation,
path:"/VideoConversation"},
MatchAlgorithm2:{
 component:MatchAlgorithm2,
path:"/MatchAlgorithm2"},
ManageBlogComments:{
 component:ManageBlogComments,
path:"/ManageBlogComments"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Scoring:{
 component:Scoring,
path:"/Scoring"},
CfDigitalWalletIntegration:{
 component:CfDigitalWalletIntegration,
path:"/CfDigitalWalletIntegration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Groups:{
 component:Groups,
path:"/Groups"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
GoogleCalendarSync:{
 component:GoogleCalendarSync,
path:"/GoogleCalendarSync"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Webinars:{
 component:Webinars,
path:"/Webinars"},
ReceiptCustomisation:{
 component:ReceiptCustomisation,
path:"/ReceiptCustomisation"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
TermsAndConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
CfMicrosoftDynamicsIntegration:{
 component:CfMicrosoftDynamicsIntegration,
path:"/CfMicrosoftDynamicsIntegration"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
GroupVideoCall2:{
 component:GroupVideoCall2,
path:"/GroupVideoCall2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
BulkCommentManagement:{
 component:BulkCommentManagement,
path:"/BulkCommentManagement"},
PremiumPlanComparison:{
 component:PremiumPlanComparison,
path:"/PremiumPlanComparison"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest"},
ApplePayIntegration:{
 component:ApplePayIntegration,
path:"/ApplePayIntegration"},
ImageThumbnail:{
 component:ImageThumbnail,
path:"/ImageThumbnail"},
FileAttachment:{
 component:FileAttachment,
path:"/FileAttachment"},
CfCryptoWalletPayment:{
 component:CfCryptoWalletPayment,
path:"/CfCryptoWalletPayment"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LiveChat2:{
 component:LiveChat2,
path:"/LiveChat2"},
VideoManagement:{
 component:VideoManagement,
path:"/VideoManagement"},
PerformanceTracker:{
 component:PerformanceTracker,
path:"/PerformanceTracker"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Bookmark2:{
 component:Bookmark2,
path:"/Bookmark2"},
CommunityForum:{
 component:CommunityForum,
path:"/CommunityForum"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},

  Home: {
component:Couponcodegenerator,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
